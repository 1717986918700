import $ from 'dom7';
import Framework7, { getDevice } from 'framework7/bundle';



// Import Icons and App Custom Styles
import '../css/icons.css';
import '../css/app.scss';

// Import F7 Styles
import 'framework7/css/bundle';
//import '../css/style.css';
// Import Cordova APIs
import cordovaApp from './cordova-app.js';

// Import Routes
import routes from './routes.js';

// Import main app component
import App from '../app.f7';
var device = getDevice();
var app = new Framework7({
  name: 'KYA SMS', // App name
  theme: 'auto', // Automatic theme detection
  el: '#app', // App root element
  component: App, // App main component
  id: 'io.kyasms.net', // App bundle ID

  // App routes
  routes: routes,
  Translation: '0',
  // Input settings
  input: {
    scrollIntoViewOnFocus: device.cordova && !device.electron,
    scrollIntoViewCentered: device.cordova && !device.electron,
  },
  // Cordova Statusbar settings
  statusbar: {
    iosOverlaysWebView: true,
    androidOverlaysWebView: true,

  },
  on: {
    init: function () {

      var f7 = this;
      if (f7.device.cordova) {
        // Init cordova APIs (see cordova-app.js)
        cordovaApp.init(f7);
        
      }
    },
  },
});

// 1.2 => Password Visibility

$(document).on('page:init', function(e) {
  // Do something here when page loaded and initialized
  console.log(e);

   /*check user langage*/

   if (window.localStorage.getItem('kyaLangage') === null) {

    var currentLangage = 'fr';
    } else {
    var currentLangage = window.localStorage.getItem('kyaLangage');
    }
    
    var langFile = './locales/' + currentLangage + '.json';
    
    
    var xhr = new XMLHttpRequest();
        xhr.open('GET', langFile, true);
        xhr.onreadystatechange = function () {
            if (xhr.readyState === 4 && xhr.status === 200) {
                var langData = JSON.parse(xhr.responseText);
                translatePage(langData);
            }
        };
        xhr.send();

});


document.addEventListener("deviceready", onDeviceReady, false);

function onDeviceReady() {

  //screen

  window.screen.orientation.lock('portrait');

  // Cordova APIs are now available
  //document.addEventListener("backbutton", onBackKeyDown, false); //Listen to the User clicking on the back button

}


function onBackKeyDown(e) {

  e.preventDefault();


  var leftp = app.panel.left && app.panel.left.opened;
  var rightp = app.panel.right && app.panel.right.opened;
  if (leftp || rightp) {
    app.panel.close();

    return false;
  } else if ($('.modal-in').length > 0) {
    app.dialog.close();
    app.popup.close();
    app.sheet.close();
    app.actions.close();
    app.popover.close();
    return false;
  } else if (app.views.main.router.currentRoute.url === '/' || app.views.main.router.currentRoute.url === '/user/landing' || app.views.main.router.currentRoute.url === '/auth/register' || app.views.main.router.currentRoute.url === '/user/dashboard')
   {
    
     // exit to app
     navigator.app.exitApp();
  } else {
    app.views.main.router.back();

  }

}

