import NotFoundPage from '../pages/404.f7';
import Landing from '../landing.f7';
import Login from '../pages/Auth/login.f7';
import Otp from '../pages/Auth/otp.f7';
import otpRegister from '../pages/Auth/otp-register.f7';
import Config from '../pages/Auth/config.f7';
import Password from '../pages/Auth/password.f7';
import resetPassword from '../pages/Auth/reset-password.f7';
import Register from '../pages/Auth/register.f7';
import Dashboard from '../pages/dashboard.f7';
import Notifications from '../pages/notifications.f7';
import Senders from '../pages/senders.f7';
import Recharges from '../pages/recharges.f7';
import Transactions from '../pages/transactions.f7';
import sendSms from '../pages/sms/send.f7';
import smsData from '../pages/sms/sms-history.f7';
import Campagne from '../pages/sms/campagne.f7';
import Messenger from '../pages/sms/messanger.f7';
import Msg from '../pages/sms/msg.f7';
import Carnets from '../pages/carnets.f7';
import Contacts from '../pages/contacts-list.f7';
import Voice from '../pages/vocal/send.f7';
import Voices from '../pages/vocal/voices.f7';
import Settings from '../pages/Auth/settings.f7';


function checkAuth({ to, from, resolve, reject }) {
  const router = this;
  var auth = window.sessionStorage.getItem("auth_kyasms");
  if (auth == "true") {
    resolve();
  } else {
    reject();
   
  }
}


function checkLanding({ to, from, resolve, reject }) {
  const router = this;
  var landing = window.localStorage.getItem('kyasms_land_v1');
  if (landing !== null) {
    resolve();
  } else {
    //redirection vers landing page
   reject();

   router.navigate({
    name: 'landingPage',
  });
   
  }
}


 
var routes = [
  {
    name: 'login',
    path: '/',
    component: Login,
    beforeEnter: [checkLanding],
  },

  {
    name: 'otp', 
    path: '/auth/otp',
    component: Otp
  },

  {
    name: 'otpRegister', 
    path: '/auth/otp/register',
    component: otpRegister
  },

  {
    name: 'landingPage', 
    path: '/user/landing',
    component: Landing
  },

  {
    name: 'Config', 
    path: '/auth/user/company',
    component: Config,
    beforeEnter: [checkAuth],
  },

  {
    name: 'password', 
    path: '/auth/password',
    component: Password
  },

  {
    name: 'resetPassword', 
    path: '/auth/password/reset',
    component: resetPassword
  },

  {
    name: 'settings', 
    path: '/auth/user/settings',
    component: Settings,
    beforeEnter: [checkAuth],
  },

  {
    name: 'register', 
    path: '/auth/register',
    component: Register
  },

  {
    name: 'dashboard', 
    path: '/user/dashboard',
    component: Dashboard,
    beforeEnter: [checkAuth],
  },

  {
    name: 'notifications', 
    path: '/user/notifications',
    component: Notifications,
    beforeEnter: [checkAuth],
  },

  {
    name: 'senders', 
    path: '/user/senders',
    component: Senders,
    beforeEnter: [checkAuth],
  },

  {
    name: 'recharges', 
    path: '/user/recharges',
    component: Recharges,
    beforeEnter: [checkAuth],
  },

  {
    name: 'transactions', 
    path: '/user/recharges/transactions',
    component: Transactions,
    beforeEnter: [checkAuth],
  },


  {
    name: 'sendsms', 
    path: '/user/sms/send',
    component: sendSms,
    beforeEnter: [checkAuth],
  },

  {
    name: 'smsLoad', 
    path: '/user/sms/history',
    component: smsData,
    beforeEnter: [checkAuth],
  },


  {
    name: 'campagne', 
    path: '/user/sms/campagne',
    component: Campagne,
    beforeEnter: [checkAuth],
  },

  {
    name: 'messenger', 
    path: '/user/sms/messenger',
    component: Messenger,
    beforeEnter: [checkAuth],
  },

  {
    name: 'msg', 
    path: '/user/sms/messenger/msg/:idSmsListEnvoi',
    component: Msg,
    beforeEnter: [checkAuth]
  },

  {
    name: 'carnets', 
    path: '/user/carnets',
    component: Carnets,
    beforeEnter: [checkAuth]
  },

  {
    name: 'contactsList', 
    path: '/user/carnets/contacts/:idGroupeContact/:labelle',
    component: Contacts,
    beforeEnter: [checkAuth]
  },

  {
    name: 'sendvoice', 
    path: '/user/vocal/send',
    component: Voice,
    beforeEnter: [checkAuth]
  },


  {
    name: 'voices', 
    path: '/user/vocal/history',
    component: Voices,
    beforeEnter: [checkAuth]
  },


  {
    path: '(.*)',
    component: NotFoundPage,
  },
];

export default routes;